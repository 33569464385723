import React, { useState } from 'react';
import axios from 'axios';

import Layout from '../../components/Layout';
import Personal from '../../components/Personal';
import HomeInterior from '../../components/HomeInterior';
import HomeInfo from '../../components/HomeInfo';
import HomeExterior from '../../components/HomeExterior';
import HomeCoverage from '../../components/HomeCoverage';
import Vehicle from '../../components/Vehicle';
import Coverage from '../../components/Coverage';
import Driver from '../../components/Driver';

const API_URL = process.env.GATSBY_API_URL || 'http://localhost:9000';

const Home = () => {
  const [personalInfo, setPersonalInfo] = useState({});
  const [info, setInfo] = useState({ drivers: [], vehicles: [] });
  const [homeInfo, setHomeInfo] = useState({});
  const [interiorInfo, setInteriorInfo] = useState({});
  const [exteriorInfo, setExteriorInfo] = useState({});
  const [coverageInfo, setCoverageInfo] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [addAuto, setAddAuto] = useState(false);

  const [vehicleInfo, setVehicleInfo] = useState({});
  const [driverInfo, setDriverInfo] = useState({});
  const [autoCoverageInfo, setAutoCoverageInfo] = useState({});

  const onAddDriver = () => {
    setInfo({ ...info, drivers: [...info.drivers, driverInfo] });
  };

  const onAddVehicle = () => {
    setInfo({ ...info, vehicles: [...info.vehicles, vehicleInfo] });
  };

  const DriverInfo = ({ driver }) => {
    return (
      <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
        <p style={{ marginRight: 10 }}>
          <b>Name:</b> {driver.name}
        </p>
        <p style={{ marginRight: 10 }}>
          <b>Date of Birth:</b> {driver.dob}
        </p>
        <p style={{ marginRight: 10 }}>
          <b>Mileage:</b> {driver.mileage}
        </p>
        <p style={{ marginRight: 10 }}>
          <b>License Number:</b> {driver.licenseNumber}
        </p>
      </div>
    );
  };

  const VehicleInfo = ({ vehicle }) => {
    return (
      <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
        <p style={{ marginRight: 10 }}>
          <b>Year:</b> {vehicle.year}
        </p>
        <p style={{ marginRight: 10 }}>
          <b>Make:</b> {vehicle.make}
        </p>
        <p style={{ marginRight: 10 }}>
          <b>Model:</b> {vehicle.model}
        </p>
      </div>
    );
  };

  const renderDrivers = () => {
    if (info.drivers.length < 1) return null;
    return info.drivers.map((d) => <DriverInfo driver={d} />);
  };

  const renderVehicles = () => {
    if (info.vehicles.length < 1) return null;
    return info.vehicles.map((v) => <VehicleInfo vehicle={v} />);
  };

  const onSubmit = async () => {
    try {
      await axios.post(`${API_URL}/quote`, {
        personalInfo,
        info: { ...homeInfo, ...interiorInfo, ...exteriorInfo, ...info },
        coverageInfo,
        autoCoverageInfo,
      });
      setSubmitted(true);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <Layout fullMenu>
      <article id="main">
        <header style={{ height: 300 }}>
          <h2>Homeowners Insurance</h2>
        </header>
        {submitted ? (
          <section className="wrapper style5">
            <h3 style={{ textAlign: 'center' }}>
              Thanks for submitting your info - Agency Insurance will be in
              contact with you shortly!
            </h3>
          </section>
        ) : (
          <section className="wrapper style5">
            <div
              className="inner"
              style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'row',
                justifyContent: 'space-around',
                flexWrap: 'wrap',
              }}
            >
              <h3 style={{ width: '100%', marginBottom: 0 }}>Personal Info</h3>
              <Personal onChange={setPersonalInfo} />
              <h3 style={{ width: '100%', marginBottom: 0 }}>Home Info</h3>
              <HomeInfo onChange={setHomeInfo} />
              <div style={{ width: '100%' }}>
                <HomeInterior onChange={setInteriorInfo} />
              </div>
              <div style={{ width: '100%' }}>
                <HomeExterior onChange={setExteriorInfo} />
              </div>
              <h3 style={{ width: '100%', marginBottom: 0 }}>
                Home Coverage Info
              </h3>
              <HomeCoverage onChange={setCoverageInfo} />
              <label style={{ width: '100%', marginTop: 0, marginRight: 20 }}>
                Would you like to add an Auto Policy?
                <br />
                <input
                  type="radio"
                  id="autoYes"
                  name="auto"
                  onChange={() => setAddAuto(true)}
                />
                <label htmlFor="autoYes">Yes</label>
                <input
                  type="radio"
                  id="autoNo"
                  name="auto"
                  onChange={() => setAddAuto(false)}
                />
                <label htmlFor="autoNo">No</label>
              </label>
              {addAuto && (
                <>
                  <h3 style={{ width: '100%', marginBottom: 0 }}>
                    Driver Info
                  </h3>
                  {renderDrivers()}
                  <Driver onChange={setDriverInfo} onAddDriver={onAddDriver} />
                  <h3 style={{ width: '100%', marginBottom: 0 }}>
                    Vehicle Info
                  </h3>
                  {renderVehicles()}
                  <Vehicle
                    onChange={setVehicleInfo}
                    onAddVehicle={onAddVehicle}
                  />
                  <h3 style={{ width: '100%', marginBottom: 0 }}>
                    Auto Coverage Info
                  </h3>
                  <Coverage onChange={setAutoCoverageInfo} />
                </>
              )}
            </div>
            <div className="inner">
              <button onClick={onSubmit}>Submit</button>
            </div>
          </section>
        )}
      </article>
    </Layout>
  );
};

export default Home;
