import React, { useState } from 'react';
import axios from 'axios';
import { Script } from 'gatsby';
import Layout from '../components/Layout';

const KS = () => {
  const [loaded, setLoaded] = useState(false);
  return (
    <Layout fullMenu hideFooter={true}>
      <Script
        src="https://insuranceform.app/embed.js"
        onLoad={() => setLoaded(true)}
      ></Script>
      {loaded && (
        <Script id="salt-embed">
          {`new SaltEmbed(
    {
      link: "Li_fyukrRUGFag",
      form: "Fo_MGBlrjDOAu0",
    }
  )`}
        </Script>
      )}
    </Layout>
  );
};

export default KS;

export const Head = () => {
  return (
    <>
      <meta
        property="og:title"
        content="Agency Insurance Kansas Home and Auto"
      />
      <meta
        property="og:description"
        content="Agency Insurance Kansas Home and Auto"
      />
      <meta
        property="og:image"
        content="https://storage.googleapis.com/agencyinsurance/agency.png"
      />
      <meta property="og:url" content="https://agencyinsurance.us/KS" />
    </>
  );
};
